*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-color: #7b7b7b #424242;
  scrollbar-width: thin;
  --mcrdark: #222;
  --mcrdarklighter: #1c1c1c;
  --mcrblue: #001e3d;
  --mcrgray: #ddd;
  --mcrgraydarker: #bbb;
  --lightblue: #1977cc;
  --lightgreen: rgb(140, 237, 140);
  --wl_winered: #CA2C5A;
  /* --wl_winedarkred: #7f1446; */
  --wl_winedarkred: #7f1546;
  --wl_blue: #0c3148;
  --wl_winesecondary: #0E3248;
  --even: #eee;
  --dark: #444444;
  --success: #0a730a;
  --warning: #8b6b00;
  /*--danger: #8b1e00;*/
  --danger: #ff0000;
  --cream: #fcf0d5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/material_icons_rounded.woff2') format('woff2');
}
.material-symbols-rounded {
  font-family: 'Material Symbols Rounded', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins Italic';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Poppins-Italic.ttf');
}
@font-face {
  font-family: 'Poppins Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Poppins-Bold.ttf');
}
@font-face {
  font-family: 'Futura Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/futura-regular.ttf');
}
@font-face {
  font-family: 'Futura Bold';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/futura-bold.ttf');
}
.font-futura {
  font-family: 'Futura Regular';
}.font-futura-bold {
  font-family: 'Futura Bold';
}
.shift-down{
  position: relative;
  top: 6px;
}
.shift-down-little{
  position: relative;
  top: 4px;
}
.shift-down-mini{
  position: relative;
  top: 1px;
}
.button-icon{
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-left: -5px;
}
.button-icon-right{
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-right: -5px;
}
.text-little{
  font-size: 0.7rem;
  position: relative;
  bottom: 0.1rem;
}
.text-bold{
  font-weight: 700;
}
.text-even{
  color: var(--mcrgray);
}
.text-white{
  color: white;
}
.text-dark {
  color: var(--wl_winesecondary);
}
.text-red {
  color: var(--wl_winered);
}
.text-wl {
  color: var(--wl_winedarkred) !important;
}
.text-even:focus {
  color: var(--mcrgray) !important;
}
.invisible-over-page{
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-in-page{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 1s ease;
  border-color: white;
}
.bg-dark{
  background-color: var(--mcrdark);
}
.bg-gray{
  background-color: var(--mcrgray);
}
.bg-lightgray{
  background-color: var(--even);
}
@media only screen and (min-width: 425px){
  .d-none-big{
    display: none;
  }
}
@media only screen and (max-width: 640px){
  .d-none-small{
    display: none;
  }
}
.even-link{
  color: var(--even);
  text-decoration: none;
}
.even-link:hover{
  color: lightgray;
  font-weight: bold;
}
.dark-link{
  color: black;
  text-decoration: none;
}
.dark-link:hover{
  color: var(--dark);
  font-weight: bold;
}
.dark-link.active{
  color: var(--dark) !important;
  font-weight: bold;
}
.counter-text {
  font-family: 'Poppins Semibold', sans-serif;
}
@media only screen and (max-width: 767px){
  .counter-text {
    font-family: 'Poppins', sans-serif;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
header{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90px;
  background-color: var(--even);
  font-family: "Poppins Semibold", sans-serif;
}
.header-seller {
  height: 135px;
}
.header-text{
  color: var(--wl_winesecondary);
  font-size: 20px;
}
.header-user-icon{
  color: var(--wl_winesecondary);
  font-size: 36px;
}
.menu-hamburger{
  font-size: 36px;
  cursor: pointer;
}
@media only screen and (max-width: 767px){
  .header-text{
    font-size: 10px;
  }
}
@media only screen and (max-width: 991px){
  .header-text{
    font-size: 16px;
  }
}
.header-icon{
  color: var(--even);
}
@media only screen and (max-width: 767px){
  .header-icon{
    display: none;
  }
}
.logo_img{
  height: 60px;
  width: 160px;
}
@media only screen and (max-width: 767px){
  .logo_img{
    height: 50px;
    width: 100px;
  }
}
nav{
  width: 180px;
}
@media only screen and (max-width: 767px){
  nav{
    width: 120px;
  } 
}
@media only screen and (max-width: 991px){
  nav{
    width: 150px;
  } 
}
@media only screen and (max-width: 991px){
  @media only screen and (max-width: 767px){
    header h2 {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 991px){
    header h2 {
      font-size: 20px;
    }
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: var(--dark);
  font-size: 14px;
  background: var(--mcrgray);
}
#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
  box-shadow: 0 2px 15px rgba(25, 119, 204, 0.1);
}
#footer .copyright img{
  height: 25px;
}

/*--------------------------------------------------------------
# Menu (sidebar)
--------------------------------------------------------------*/
.sidebar-menu {
  background-color: var(--mcrdarklighter);
  width: 0;
  overflow-x: hidden;
}
.nav-link {
  text-decoration: none;
  color: var(--even)
}
.nav-link.active {
  text-decoration: none;
  color: var(--wl_winered) !important;
}
.nav-link:active,.nav-link:visited {
  text-decoration: none;
  color: var(--even)
}
.nav-link:hover {
  text-decoration: none;
  color: var(--wl_winered);
  font-weight: 700;
}
.nav-link:hover .material-symbols-rounded{
  text-decoration: none;
  color: var(--wl_winered);
  font-weight: 700;
}

/*--------------------------------------------------------------
# Hero (central space of page)
--------------------------------------------------------------*/
.centralbody-container{
  margin-top: 90px;
}
.hero {
  position: relative;
  min-height: calc(100vh - 147px);
  width: 100vw;
  background-size: cover;
  transition: width 0.2s ease;
}
@media only screen and (max-width: 767px){
  .hero{
    min-height: calc(100vh - 168px);
    transition: width 0.2s ease;
  }
}
.seller-bg{
  background-color: var(--cream);
  background-image: url('../public/img/wine_bottles_background.svg');
  background-repeat: repeat;
}
.login-square{
  background-color: var(--wl_winered);
}
.menu-open {
  width: calc(100vw - 200px);
}
.overlay {
  position: absolute;
  background-color: var(--mcrdarklighter);
  width: 0;
  height: calc(100%);
  z-index: 10;
  border-left: 1px solid gray;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  right: 0;
  transition: width 0.5s ease;
}
.loading-overlay {
  position: absolute;
  z-index: 1;
  background-color: var(--mcrdarklighter);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.half-opacity {
  opacity: 0.4;
}
.overlay-top-buttons-container{
  position: fixed;
  background-color: var(--mcrdarklighter);
  z-index: 11;
  margin-top: -40px;
  margin-left: -8px
}
@media only screen and (max-width: 767px){
  .overlay-top-buttons-container{
    margin-top: -40px;
  }
}
.visible {
  width: calc(100vw - 400px);
  max-width: 990px;
}
@media only screen and (max-width: 767px){
  .visible {
    width: 100vw;
  }
}
.corner-right {
  position: absolute;
  top: 95px;
  right: 0;

}
.ag-grid-table{
  height: calc(100% - 45px);
}
@media only screen and (max-width: 767px){
  .ag-grid-table{
    height: calc(100% - 45px);
  }
}
.form-header{
  position: fixed;
  z-index: 11;
  top: 90px;
  border-bottom: 1px solid var(--dark);
}
@media only screen and (min-width: 768px){
  .form-header{
    min-height: 90px;
  }
}
.login-form {
  background-color: var(--mcrgray);
}
.answer{
  width: 99%;
  margin: auto;
  cursor: pointer;
  background-color: white;
  padding: 20px 5px;
  border: 1px solid var(--mcrgray);
  border-radius: 5px;
}
.selected {
  background-color: var(--lightgreen)
}

/**
* Photogallery *
*/
.photogallery-container {
  position: relative;
  overflow-x: hidden;
  background-color: var(--mcrdark);
}
.photos-inner-container {
  transition: margin-left 0.15s ease-out;
}
.no-photos-info {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}
.photo-button-zoomout{
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
}
.photo-button-left {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}
.photo-button-right {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.photo-button-delete {
  position: absolute;
  top: 5px;
  right: 5px;
}
/**
* Video *
*/
.video-player {
  position: relative;
}
.play-button {
  cursor: pointer;
  position: absolute;
  height: 30%;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/**
* Buttons *
*/
.mcr-btn {
  margin-left: 25px;
  background: var(--dark);
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: 1px solid var(--dark);
}
.border-even{
  border: 1px solid var(--even);
}
.success {
  background-color: var(--success);
  border: none !important;
}
.success:hover {
  background: var(--dark) !important;
  border: 1px solid var(--success) !important;
}
.warning {
  background-color: var(--warning);
  border: none !important;
}
.warning:hover {
  background: var(--dark) !important;
  border: 1px solid var(--warning) !important;
}
.danger {
  background-color: var(--danger);
  border: none !important;
}
.danger:hover {
  background: var(--dark) !important;
  border: 1px solid var(--danger) !important;
}
.small-btn {
  font-size: 12px;
  padding: 4px 12.5px;
  margin-left: 10px;
}
.btn-with-icon {
  padding-top: 0;
  padding-left: 15px;
}
.btn-with-icon .material-symbols-rounded{
  position: relative;
  top: 6px;
}
.btn-with-icon:not(.no-icon-sm):not(.icon-back){
  padding: 0px 7px 7px 7px !important;
  margin: 2px !important;
}
.btn-with-icon:not(.no-icon-sm).icon-back{
  padding: 0px 7px 7px 7px !important;
}
.btn-with-icon .material-symbols-rounded{
  font-size: 22px;
}

.btn-micro-with-icon {
  padding-top: 0;
  padding-left: 8px;
}
.btn-micro-with-icon .material-symbols-rounded{
  position: relative;
  top: 5px;
}
.btn-micro-with-icon:not(.no-icon-sm){
  padding: 0px 6px 6px 6px !important;
  margin: 2px !important;
}
.btn-micro-with-icon .material-symbols-rounded{
  font-size: 15px;
}
.down-left{
  color: #cf0000bd;
  position: absolute;
  left: 4px;
  bottom: 4px;
}
.freesearch-side-buttons-container {
  padding-top: 36px;
}
.mcr-btn.cream {
  background: var(--cream) !important;
  color: var(--dark) !important;
}
.mcr-btn:disabled {
  background-color: var(--mcrgray);
  border-color: darkgrey;
  color: var(--dark);
}
.mcr-btn:hover {
  background: var(--mcrblue);
  color: #fff;
  border: 1px solid #fff
}
.mcr-btn:disabled:hover {
  background-color: var(--mcrgray);
  border-color: darkgrey;
  color: var(--dark);
  border: none;
}
.btn_length_it{
  width: 160px;
}
.btn_length_en{
  width: 160px;
}
.btn_length_de{
  width: 290px;
}
.btn.border-even{
  border: 1px solid var(--mcrgraydarker);
}
.btn.border-even.active{
  border: 1px solid var(--mcrgraydarker);
  color: var(--even) !important;
}
@media (max-width: 767px) {
  .mcr-btn {
      margin: 0 7px 0 7px;
      padding: 6px 18px;
  }
  .small-btn {
    font-size: 14px;
    padding: 4px 12.5px;
    margin-left: 10px;
  }
}
.invisible-btn{
  appearance: none;
  border: none;
  background: transparent;
}

/**
* Inputs and forms *
*/
.wl-form{
  color: var(--even);
  background-color: var(--wl_winedarkred);
  padding: 10px !important;
}
.filter-card-button{
  color: var(--even);
  background-color: var(--wl_winedarkred);
  width: 160px;
  cursor: pointer;
}
.filter-card-button-selected{
  color: var(--even);
  width: 160px;
  cursor: pointer;
  border: 3px solid white;
  background-color: var(--wl_winered);
}
.custom-control-input:checked {
  color: #fff;
  border-color: var(--wl_winered);
  background-color: var(--wl_winered);
}

.rounded-left{
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.mcr-checkbox{
  height: 24px;
  width: 24px;
}
.mcr-checkbox-wine{
    position: relative;
    appearance: none;
    color: #fff;
    height: 24px;
    width:24px;
    background-color: #fff;
    border: 1px solid #cfd8dc !important;
    border-radius: 4px;
}
.mcr-checkbox-wine:checked{
  appearance: none;
    color: #fff;
    height: 24px;
    width:24px;
    background-color: #900020;
    border: 1px solid #cfd8dc !important;
    border-radius: 4px;
}
.mcr-checkbox-wine:checked::after {
  content: '✓';
  position: absolute;
  left: 2px;
  top: -8px;
  font-size: 1.5em;
}
input:disabled{
  background-color: var(--mcrgraydarker) !important;
}
.autocomplete-disable div {
  background-color: #bbbbbb;
  border-radius: 4px;
  color: black;
  font-weight: 700;
}
textarea.form-control {
  min-height: 100px;
}
textarea:disabled{
  background-color: var(--mcrgraydarker) !important;
}
.material-form-control div {
  height: 38px;
  padding-left: 14px;
  z-index: 0;
}
.material-form-control.is-invalid {
  border: 1px solid #cf0000bd !important;
  
}
.container.is-invalid {
  border: 1px solid #cf0000bd !important;
  border-radius: 5px;
}
.material-form-control.custom-disabled {
  background-color: rgb(186, 186, 186);
}
.dark-select {
  background-color: var(--dark);
  color: var(--even);
  background-image: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='8' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='rgb(200,200,200)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
  background-position: 90% 60%;
}
.little-select {
  padding-top: 5px;
  width: 90px;
  height: 34px;
}
input[type="file"], input[type="file"]:disabled {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.form-control.disabled {
  background: #bbbbbb;
}

/**
* Modals *
*/
.custom-modal:not(.fade) {
  display: block;
  background-color: rgba(130, 130, 130, 0.6);
  z-index: 12;
}
/**
* Toasts *
*/
.toast-raised {
  bottom: 3em;
  right: 2em;
}
.toast-lowered {
  top: 110px;
}
/* Overrides React-Tostify default class for toast body */
.Toastify__toast {
  border: 1px solid var(--even);
}
